<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Payment: </span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <b-tabs card>

            <b-tab title="General">

              <table class="table" v-if="quote">
                  <tbody>
                      <tr v-for="(v, k, index) in quote" :key="index" v-if="k !== 'id' && k !== 'user_id' && k !== 'claim_id' && k !== 'options' && k !== 'status' && k !== 'claim' && k !== 'payment_to_id'">
                          <th v-if="k === 'status_display'">Status</th>
                          <th v-else-if="k === 'reference'">Claim Reference</th>
                          <th v-else>{{ k|capitalize }}</th>
                          <td v-if="k === 'payment_details'">
                            <p v-for="(pk,pv,  pi) in quote.payment_details" :key="pi">
                              <b class="text-muted">{{pv|capitalize}}:</b> {{ pk }}</p>
                          </td>
                          <td v-else-if="k === 'reference'"><router-link :to="{name: 'Claim', params: {id: quote.claim}}">{{ v }}</router-link></td>
                          <td v-else-if="k === 'payment_to'"> 
                            <router-link v-if="quote.payment_to === 'Policy Holder'" :to="{name: 'Customer', params: {id: quote.payment_to_id}}">{{ v }}</router-link>
                            <router-link v-if="quote.payment_to === 'Vet'" :to="{name: 'Vet', params: {id: quote.payment_to_id}}">{{ v }}</router-link>
                          </td>
                        <td v-else>{{ v }}</td>
                      </tr>
                  </tbody>
              </table>

            </b-tab>

            <notes-tab v-if="quote" v-bind:type="'payment'" v-bind:object="quote.id"></notes-tab>

          </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="pull-left">Back</b-button>
          <b-button :to="{ name: 'PaymentEdit', params: { id: quote.id } }" variant="outline-dark" class="float-right mr-2">Edit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>
  </div>
</template>

<script>
  import axios from '../../../shared/axios/auth'
  import notesTab from '../utils/tabs/notes'

  export default {
    components: {
      'notes-tab': notesTab,
    },
    name: 'Quote',
    props: {
      caption: {
        type: String,
        default: 'Quote Reference '
      },
    },
    data: () => {
      return {
        quote: null
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getQuote() {
          let url = '/payments/' + this.$route.params.id + '/';

        axios.get(url).then(
          response => (
            this.quote = response.data
          )
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.quote
      }
    },
    mounted() {
      this.getQuote()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
