<template>

  <b-tab title="Notes">

    <div v-if="notes && notes.data.length > 0">

      <table>
      <tr>
          <td class="p-2">
          <b-button v-if="notes.pagination.first_page_url === null">&#60;&#60; First</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.first_page_url);">&#60;&#60; First</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="notes.pagination.previous_page_url === null">&#60; Prev</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.previous_page_url);">&#60; Prev</b-button>
          </td>
          <td class="p-2">
          <b>Page
          {{ notes.pagination.current_page === null ? 1 : notes.pagination.current_page }}
          of 
          {{ notes.pagination.last_page }}
          </b>
          </td>
          <td class="p-2">
          <b-button v-if="notes.pagination.next_page_url === null">Next &#62;</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.next_page_url);">Next &#62;</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="notes.pagination.last_page_url === null">Last &#62;&#62;</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.last_page_url);">Last &#62;&#62;</b-button>
          </td>
      </tr>
      </table>

      <b-card  v-for="(note, indexI) in notes.data" :key="'key4_'+indexI">
        <b-card-text>
          <table>
            <tr>
              <td class="p-2"><b>Note Type:</b></td>
              <td class="p-2">{{note.type}}</td>
            </tr>
            <tr>
              <td class="p-2"><b>Note Text:</b></td>
              <td class="p-2">{{note.message_body}}</td>
            </tr>
          </table>
        </b-card-text>
        <template #footer>
          <span class="float-left"><b>Created by:</b>&nbsp;<em>{{note.created_by}}</em></span>
          <span class="float-right"><b>Created:</b>&nbsp;<em>{{note.created}}</em></span>
        </template>
      </b-card>

            <table>
      <tr>
          <td class="p-2">
          <b-button v-if="notes.pagination.first_page_url === null">&#60;&#60; First</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.first_page_url);">&#60;&#60; First</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="notes.pagination.previous_page_url === null">&#60; Prev</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.previous_page_url);">&#60; Prev</b-button>
          </td>
          <td class="p-2">
          <b>Page
          {{ notes.pagination.current_page === null ? 1 : notes.pagination.current_page }}
          of 
          {{ notes.pagination.last_page }}
          </b>
          </td>
          <td class="p-2">
          <b-button v-if="notes.pagination.next_page_url === null">Next &#62;</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.next_page_url);">Next &#62;</b-button>
          </td>
          <td class="p-2">
          <b-button v-if="notes.pagination.last_page_url === null">Last &#62;&#62;</b-button>
          <b-button v-else @click="loadNotes(notes.pagination.last_page_url);">Last &#62;&#62;</b-button>
          </td>
      </tr>
      </table>

    </div>

    <div v-else>
      <p class="text-center">No notes added yet.</p>
    </div>

    <add-note :type="type" :object="object"></add-note>
    <b-button v-b-modal="'add-note'">Add {{ type.ucwords() }} Note</b-button>

  </b-tab>

</template>

<script>
import axios from '../../../../shared/axios/auth-no-loader'
import addNote from '../../claims/utils/add-note'

export default {
    components: {
      addNote
    },
    name: 'NotesTab',
    props: {
      type: {
        required: true,
      },
      object: {
        required: true,
      },
    },
    data () {
        return {
            loaded: false,
            response: {},
            notes: null,
        }
    },
    methods: {
      loadNotes (url=null) {

        if(url == null){
          url = '/notes/?'+this.type+'=' + this.object
        }

        axios.get(url).then(
          response => {
            this.notes = response.data
            this.scrollToTop()
          }
        )

      },
      scrollToTop(){
        window.scrollTo(0,0);
      }
    },
    mounted() {
      this.loadNotes()
    }

}

</script>
