<template>
  <div>
    <b-modal
      id="add-note"
      ref="modal"
      :title="title"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      ok-title="Submit"
      centered
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Note"
          label-for="note-input"
          invalid-feedback="Note is required"
          :state="noteState"
        >
        <b-form-textarea
            id="note-input"
            v-model="note"
            :state="noteState"
            required
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
        ></b-form-textarea>

        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
  
  import axios from '../../../../shared/axios/auth-no-loader'

  export default {
    props: {
      type: {
        required: true
      },
      object: {
        required: true,
      },
    },
    data() {
      return {
        note: '',
        noteState: null,
        title: 'Add a ' + this.type.ucwords() + ' note',
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.noteState = valid
        return valid
      },
      resetModal() {
        this.name = ''
        this.nameState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names
        let data = {
            text: this.note
        }
        if(this.type == "claim"){
          data["claim"] = this.object
        }
        if(this.type == "complaint"){
          data["complaint"] = this.object
        }
        if(this.type == "policy"){
          data["policy"] = this.object
        }
        if(this.type == "payment"){
          data["payment"] = this.object
        }
        if(this.type == "customer"){
          data["customer"] = this.object
        }
        if(this.type == "vet"){
          data["vet"] = this.object
        }

        axios.post('/notes/', data).then(
          response => {
            response
            this.note = ''

            this.noteState = false

            // Hide the modal manually
            //
            this.$nextTick(() => {
              this.$bvModal.hide('add-note')
            })

            this.$parent.$parent.loadNotes()
            alert("note added")

          }
        ).catch(error => {

          console.log(error)

            this.noteState = false

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log(error.response)
          })
        
      }
    }
  }
</script>